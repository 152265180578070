<template>
  <div>
    <el-table :data='dataList' max-height='1000' highlight-current-row border>
      <el-table-column prop='COLUMN_NAME' label='列名' width='200'></el-table-column>
      <el-table-column prop='DATA_TYPE' label='数据类型' width='100'></el-table-column>
      <el-table-column prop='COLUMN_TYPE' label='列类型' width='200'></el-table-column>
      <el-table-column prop='COLUMN_KEY' label='主键' width='100'></el-table-column>
      <el-table-column prop='COLUMN_COMMENT' label='列注释' width='300'></el-table-column>
      <el-table-column prop='EXTRA' label='其他' width='200'></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'ColumnTable',
  props: {
    dataList: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>