<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <div
          v-loading='loading'
          element-loading-background='rgba(255, 255, 255, 0.01)'
          element-loading-text='拼命加载中...'
          style='height:100%; padding: 0'
        >
          <el-row :gutter='15'>
            <el-col :md='24' :xs='24'>
              <el-card shadow='hover' style='margin-bottom:15px;'>
                <template slot='header'>
                  <el-link type='primary' @click='init'>数据库信息<i class='el-icon-refresh'></i></el-link>
                </template>
                <div>
                  <span class='table_schema'><span class='title'>库名：</span>{{ schema_data.table_schema || '' }}</span>
                  <span class='table_rows'><span class='title'>总行数：</span>{{ schema_data.table_rows || '' }}</span>
                  <span class='data_length'><span class='title'>数据大小：</span>{{ $utils.byteToSize(schema_data.data_size)
                    }}</span>
                  <span class='index_length'><span
                    class='title'>索引总量：</span>{{ $utils.byteToSize(schema_data.index_size) }}</span>
                </div>

                <el-divider content-position='left'>表信息 &nbsp;&nbsp; <i class='el-icon-info'>双击行可查看指定表的数据字典</i>
                </el-divider>
                <el-row>
                  <el-col :span='24'>
                    <el-form :model='searchCondition' label-position='left' inline @submit.native.prevent>
                      <el-form-item label='表名：'>
                        <el-input v-model='searchCondition.table_name' placeholder='表名搜索'
                                  @keyup.enter.native='handleQuery' clearable></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type='primary' icon='el-icon-search' @click='handleQuery' :disabled='loadingStatus'>
                          搜索
                        </el-button>
                      </el-form-item>
                    </el-form>
                  </el-col>
                </el-row>

                <div class='default-table'>
                  <!--    渲染表格-->
                  <m-table highlight-current-row :showIndex='true' :offset='offset' max-height='500'
                           :tableData='dataList' :columns='columns' @sort-change='changeTableSort'
                           :loading='loadingStatus' @row-dblclick='handleClick' border>
                    <el-table-column label='操作项' width='260' fixed='right' align='center'>
                      <template slot-scope='{row}'>
                        <el-button type='text' icon='el-icon-tickets' @click='showTableDict(row.TABLE_NAME)'>字典
                        </el-button>
                        <el-button type='text' icon='el-icon-document-checked' @click='optimizeTable(row)'>碎片优化
                        </el-button>
                        <el-button type='text' icon='el-icon-view' @click='handlePreview(row)'>预览</el-button>
                      </template>
                    </el-table-column>
                  </m-table>
                </div>
                <!--    渲染分页-->
                <m-pagination :total.sync='total' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                              @pagination='getList' />
                <tips :data='tips'>

                </tips>
              </el-card>
              <el-divider content-position='left'>
                <el-link type='priamry' @click='getTableCols' icon='el-icon-tickets'>数据字典(全部表) <i
                  class='el-icon-refresh'></i></el-link>
              </el-divider>
              <div>
                <div v-for='(table,index) in tableColsList' :key='index'>
                  <h3>表名：{{ table.table_info.TABLE_NAME }}</h3>
                  <div>
                    行数：{{ table.table_info.TABLE_ROWS }}，自增ID：{{ table.table_info.AUTO_INCREMENT }}
                    [引擎：{{ table.table_info.ENGINE }}/大小：{{ $utils.byteToSize(table.table_info.DATA_LENGTH)
                    }}/索引：{{ $utils.byteToSize(table.table_info.INDEX_LENGTH)
                    }}/碎片：{{ $utils.byteToSize(table.table_info.DATA_FREE) }}]
                  </div>
                  <el-row>
                    <el-col :lg='12' :md='12' :xs='24'>
                      <div style='padding: 10px'>
                        <ColumnTable :data-list='table.col_list' v-if='table.col_list'></ColumnTable>
                      </div>
                    </el-col>
                    <el-col :lg='12' :md='12' :xs='24'>
                      <div>
                        <TableDataChart :table-name='table.table_info.TABLE_NAME'
                                        v-if='table.table_info.TABLE_NAME'></TableDataChart>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 预览界面 -->
      <el-dialog :title='preview.title' :visible.sync='preview.open' width='80%' top='5vh' append-to-body
                 :close-on-click-modal='false'>
        <el-tabs v-model='preview.activeName'>
          <el-tab-pane
            v-for='(value, key) in preview.data'
            :label='key'
            :name='key'
            :key='key'
          >
            <div style='position: absolute;top:10px;right:10px;text-align: center'>
              <el-button plain type='primary' icon='el-icon-document-copy' @click.stop.native='copyData(value)'>复制
              </el-button>
            </div>
            <div style='height: 80vh;overflow-y: scroll'>
              <pre>{{ value }}</pre>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
    </page-header-layout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ColumnTable from '@/pages/system/db/ColumnTable'
import TableDataChart from '@/pages/system/db/TableDataChart'

export default {
  name: 'index',
  components: { TableDataChart, ColumnTable, PageHeaderLayout },
  computed: {
    columns() {
      let cols = []
      Object.keys(this.colMap).forEach((k) => {
        //
        if (['DATA_FREE', 'INDEX_LENGTH', 'DATA_LENGTH'].indexOf(k) > -1) {
          let col = {
            title: this.colMap[k],
            field: k,
            value: `_${k}`,
            width: 120,
            align: 'right',
            sortable: 'custom'
          }
          cols.push(col)
        } else {
          cols.push({
            title: this.colMap[k],
            value: k,
            width: 120,
            align: 'center',
            sortable: 'custom'
          })
        }


      })
      return cols
    }
  },
  data() {
    return {
      loading: false,
      loadingStatus: false,
      searchCondition: { table_name: null },
      offset: 0,
      schema_data: {},//库新
      dataList: [],//表数据
      total: 0,
      pageData: { page_size: 10, current_page: 1 },
      tableColsList: [],//列数据
      orderSort: {},
      colMap: {
        // 'TABLE_CATALOG': '登记目录',
        // 'TABLE_SCHEMA': '所属库名',
        'TABLE_NAME': '表名称',
        'TABLE_COMMENT': '表的注释',
        // 'TABLE_TYPE': '表类型[system view|base table]',
        'ENGINE': '数据库引擎',//[MyISAM|CSV|InnoDB]
        // 'VERSION': '版本',//，默认值10
        // 'ROW_FORMAT': '行格式',//[Compact|Dynamic|Fixed]
        'TABLE_ROWS': '数据量（行）',
        'AVG_ROW_LENGTH': '平均行长度',
        'DATA_LENGTH': '数据长度',
        'MAX_DATA_LENGTH': '最大数据长度',
        'INDEX_LENGTH': '索引长度',
        'DATA_FREE': '空间碎片',
        // '_DATA_FREE': '空间碎片',
        'AUTO_INCREMENT': '自增主键当前值',
        'CREATE_TIME': '表的创建时间',
        'UPDATE_TIME': '表的更新时间',
        'CHECK_TIME': '表的检查时间',
        'TABLE_COLLATION': '表的字符校验编码集'
        // 'CHECKSUM': '校验和',
        // 'CREATE_OPTIONS': '创建选项',
        // 'TABLE_COMMENT': '表的注释、备注'
      },
      tips: [
        'Mysql数据库中的碎片主要来源于以下几个方面：',
        ' - 插入、更新和删除操作：当对索引列进行插入、更新和删除操作时，会导致索引数据的变动，从而产生碎片。特别是delete操作，会在存储中产生空白的空间，当有新数据插入时，MySQL会试图在这些空白空间中保存新数据，但是总是有部分空间不会被利用，久而久之就会导致碎片产生。\n',
        ' - 索引的删除和重建：当删除索引或重新构建索引时，原有的索引数据会被删除或重新生成，这也可能导致碎片的产生。\n',
        ' - 索引列的数据类型变更：如果对索引列的数据类型进行了变更，比如从INT变为VARCHAR，也会导致索引碎片的产生。\n',
        '以上情况都会导致Mysql数据库中的数据存储变得不连续、不规则，从而产生碎片'
      ],
      // 预览参数
      preview: {
        open: false,
        title: '代码预览',
        data: {},
        activeName: 'controller'
      },
      //筛选条件缓存key
      pageSearchKey:'page_search_key:system_db'

    }
  },
  methods: {
    init() {
      if (sessionStorage.getItem(this.pageSearchKey)) {
        this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
      }

      this.getInfo()
      this.handleQuery()
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.orderSort = {}
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    changeTableSort({ prop, order }) {
      // let orderSort = {}
      if (order) {
        this.orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      // this.orderSort = orderSort
      this.getList()
    },
    async getInfo() {
      let { schema_data } = await this.$api.getSystemDbInfo()
      this.schema_data = schema_data
    },
    async getList() {
      this.loadingStatus = true
      let params = this.searchCondition
      Object.assign(params, this.pageData, { orderBy: this.orderSort })
      let { list, pages } = await this.$api.getSystemDbList(params)
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 10
      this.total = pages.total || 0
      this.offset = pages.offset || 0
      this.loadingStatus = false
    },
    async getTableCols() {
      let { list } = await this.$api.getSystemDbCols()
      this.tableColsList = list
    },
    // eslint-disable-next-line no-unused-vars
    handleClick(row, column, event) {
      this.showTableDict(row.TABLE_NAME)
    },
    async showTableDict(table_name) {
      let { list } = await this.$api.getSystemDbCols({ table_name: table_name })
      this.tableColsList = list
    },
    //碎片优化
    async optimizeTable(row) {
      if (!row.TABLE_NAME) {
        this.$notify.warning('表名未知')
        return
      }
      if (row.DATA_FREE > 0) {

        this.$confirm('确定优化表碎片空间，请在业务不频繁的时候操作！！！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.$api.optimizeSystemDbTable({ table_name: row.TABLE_NAME })
          this.$notify.success('成功！')

        }).catch(() => {
          this.$notify.info('已取消')
        })

      } else {
        this.$notify.warning(`该表碎片大小为：${row.DATA_FREE}`)
      }
    },
    async getChartData(row) {
      await this.$api.getSystemDbTableChartData({ table_name: row.TABLE_NAME })
    },
    //预览
    async handlePreview(row) {

      let { data } = await this.$api.getSystemDbGenPreview(row.TABLE_NAME)
      this.preview.data = data
      this.preview.open = true
    },
    //复制到剪切板
    copyData(data) {

      const textarea = document.createElement('textarea')
      textarea.setAttribute('readonly', 'readonly')

      textarea.value = data
      document.body.appendChild(textarea)
      textarea.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        // alert('复制成功')
        this.$notify.success('已复制到剪切板')
      }
      document.body.removeChild(textarea)
    }

  },
  created() {
    this.init()
  }
}
</script>

<style scoped>
.table_schema, .table_rows, .data_length {
  margin-right: 10px;
  font-size: 14px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}
</style>